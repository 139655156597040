import { germanDayTime } from "../date";
import {filesize} from "filesize";

export const uploadTableHeaders = [
  {
    id: "import_id",
    name: "Prozess ID",

  },
  {
    id: "original_file_name",
    name: "Dateiname",
  },
  {
    id: "result",
    name: "Resultat",
  },
  {
    id: "user_name",
    name: "Benutzer",
  },
  {
    id: "created_at",
    name: "Hochgeladen am",
    customFormatter: germanDayTime
  },
];

export const uploadTableHeadersMore = [
  {
    id: "import_id",
    name: "Prozess ID",

  },
  {
    id: "file_name",
    name: "interner Dateiname",
  },
  {
    id: "file_size",
    name: "Dateigröße",
    customFormatter: filesize
  },
  ...uploadTableHeaders.filter(({id}) => id !== "import_id")
];