import React, { useEffect, useState } from "react";
import { useUploadHistory } from "../hooks/useApi";
import { Error, Loading } from "./Helpers";
import Table from "./table/Table";
import {
  uploadTableHeaders,
  uploadTableHeadersMore,
} from "../lib/constants/upload";
import SearchBar from "./SearchBar";
import { reversValue } from "../lib/text";

const UploadHistory = ({ tabFilter }) => {
  const { data, isLoading, error } = useUploadHistory();
  const [entries, setEntries] = useState([]);
  const [slice, setSlice] = useState(50);
  const [simpleMode, setSimpleMode] = useState(true);

  useEffect(() => {
    if (data.history && Array.isArray(data.history) && data.history.map) {
      if (tabFilter) {
        onSearch(tabFilter);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, tabFilter, simpleMode]);

  if (isLoading) {
    return <Loading />;
  }

  const extractResultType = (result) => {
    const resultParts = result.split(" ");
    if (
      result.includes("importiert") &&
      resultParts.length === 3 &&
      !isNaN(resultParts[0]) &&
      resultParts[2] === "importiert"
    ) {
      return {
        count: parseInt(resultParts[0]),
        type: resultParts[1],
      };
    }
    return false;
  };

  const mergeResultTypes = (resultType1, resultType2) => {
    if (!resultType1) {
      return resultType2;
    }
    if (!resultType2) {
      return resultType1;
    }

    if (resultType1.type === resultType2.type) {
      return {
        count: resultType1.count + resultType2.count,
        type: resultType1.type,
      };
    }
    return;
  };

  const entriesToSimpleEntries = (entries) => {
    const result = {};
    entries.forEach((entry) => {
      if (result[entry.import_id]) {
        result[entry.import_id].resultType = mergeResultTypes(
          result[entry.import_id].resultType,
          extractResultType(entry.result)
        );
        result[entry.import_id].files++;
      } else {
        result[entry.import_id] = { ...entry, files: 1, resultType: extractResultType(entry.result) };
      }
    });
    for (const key in result) {
      if (result.hasOwnProperty(key)) {
        const element = result[key];
        if (element.files > 1 && element.resultType) {
          element.result = `${element.resultType.count} ${
            element.resultType.type
          } importiert`;
        }
      }
    }
    return Object.values(result);
  };

  const FILTERS = uploadTableHeaders.reduce((acc, header) => {
    acc[header.id] = header.name;
    return acc;
  }, {});
  const onSearch = (_filters = []) => {
    if (!Array.isArray(_filters)) {
      return;
    }
    const filters = _filters.map((filter) => {
      const [key, value] = filter.split(":", 2);
      let originalKey = Object.keys(FILTERS).find(
        (k) => FILTERS[k] === key.trim()
      );
      return { key: originalKey, value: reversValue(value.trim()) };
    });
    const filtered = data.history.filter((item) =>
      filters.every((filter) => {
        if (Array.isArray(item[filter.key])) {
          return item[filter.key].includes(filter.value);
        }
        return String(item[filter.key]) === String(filter.value);
      })
    );
    if (simpleMode) {
      setEntries(entriesToSimpleEntries(filtered));
    } else {
      setEntries(filtered);
    }
  };

  if (error) {
    return <Error error={error} />;
  }
  return (
    <div>
      <SearchBar
        suggestionList={entries}
        mapKeyValues={FILTERS}
        onFilter={onSearch}
        preFiltered={tabFilter}
      />

      <label>
        <input
          type="checkbox"
          checked={!simpleMode}
          onChange={(e) => {
            if (!e.target.checked) {
              setSimpleMode(true);
            } else {
              setSimpleMode(false);
            }
          }}
        />{" "}
        Auch ZIP-Inhalte anzeigen
      </label>
      <Table
        data={entries.slice(0, slice)}
        headers={simpleMode ? uploadTableHeaders : uploadTableHeadersMore}
        key={simpleMode ? "simple" : "more"}
      />
      {slice < entries.length && (
        <>
          {" "}
          Gefilterete Anzeige {slice} von {entries.length} Zeilen{" "}
          <a
            className="bdr-button bdr-button--secondary"
            onClick={(e) => {
              e.preventDefault();
              setSlice(slice + 100);
            }}
            href="/finance"
          >
            Mehr anzeigen
          </a>
        </>
      )}
    </div>
  );
};
export default UploadHistory;

export const uuidToProcessId = (uuid) =>
  uuid.replace(/_/g, "").replace(/-/g, "");
