import React, { useEffect, useState } from "react";
import { useSales } from "../hooks/useApi";
import { Error, Loading } from "./Helpers";
import Table from "./table/Table";
import { sortByKey } from "../lib/sort";
import { salesSearchMapping, salesTableHeaders } from "../lib/constants/sales";
import SearchBar from "./SearchBar";
import { formatValue, reversValue } from "../lib/text";
import { Link } from "react-router-dom";
import Button from "./form/Button";

const FinanceSales = () => {
  const { data, isLoading, error } = useSales();
  const [sales, setSales] = useState([]);
  const [slice, setSlice] = useState(50);
  const [sumNet, setSumNet] = useState(0);

  useEffect(() => {
    if (data.sales) {
      setSales(data.sales);
    }
  }, [data]);

  const sortBy = (key, sortDirection = "ASC") => {
    setSales(sortByKey(sales, key, sortDirection));
    return false;
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  const onSearch = (_filters = []) => {
    if (!Array.isArray(_filters)) {
      return;
    }
    const filters = _filters.map((filter) => {
      const [key, value] = filter.split(":", 2);
      let originalKey = Object.keys(salesSearchMapping).find(
        (k) => salesSearchMapping[k] === key.trim()
      );

      return { key: originalKey, value: reversValue(value.trim()) };
    });
    if(!data.sales) {
      return;
    }
    const filtered = data.sales.filter((sale) =>
      filters.every((filter) => {
        if (Array.isArray(sale[filter.key])) {
          return sale[filter.key].includes(filter.value);
        }
        return formatValue(sale[filter.key]) === filter.value;
      })
    );
    setSumNet(filtered.reduce((sum, sale) => sum + sale.net, 0));
    setSales(filtered);
  };

  return (
    <div>
      <div className="button-group-right">
        <Link to="/sales/new">
          <Button
            text="Neuer sonstiger Umsatz"
            type="button"
            variant="secondary"
          />
        </Link>
        <br />
      </div>
      <SearchBar
        suggestionList={sales}
        onFilter={onSearch}
        mapKeyValues={salesSearchMapping}
        placeholder="Suche nach sonstigen Umsatz (Faktura, Kunde,..)"
      />
      <Table
        data={sales.slice(0, slice)}
        onSort={sortBy}
        headers={salesTableHeaders}
        footers={[
          {
            id: "faktura",
            text: "Summe",
          },
          {},
          {},
          {
            id: "net",
            text: sumNet,
          },
        ]}
      />
      {slice < sales.length && (
        <>
          {" "}
          Gefilterete Anzeige {slice} von {sales.length} Zeilen{" "}
          <a
            className="bdr-button bdr-button--secondary"
            onClick={(e) => {
              e.preventDefault();
              setSlice(slice + 500);
            }}
            href="/finance"
          >
            Mehr anzeigen
          </a>
        </>
      )}
    </div>
  );
};

export default FinanceSales;
